<script setup>
import autoAnimate from '@formkit/auto-animate';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useForm } from '@inertiajs/vue3';
import Table from '@/Components/Table/Table.vue';
import tBody from '@/Components/Table/tBody.vue';
import Icon from '@/Components/Icon.vue';
import Right from '@i/expand_more-right.svg';
import AcceptedApplicationForm from './AcceptedApplicationForm.vue';
import ShiftSummary from './ShiftSummary.vue';
import ModalWithin96Hours from './ModalWithin96Hours.vue';
import RejectedApplicationForm from './RejectedApplicationForm.vue';
import AbsenceForm from '@/Pages/Location/Planning/Partials/AbsenceForm.vue';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    shift: Object,
    liked_flexer_ids: Object,
    sortBy: {
        type: String,
        default: '',
    },
    sortByDesc: {
        type: String,
        default: '',
    },
    dropdownOpen: Boolean,
    isOrange: Boolean,
});

defineEmits(['update:sortBy', 'update:orderDir']);
const summary = ref();
const show = ref(false);

onMounted(() => {
    autoAnimate(summary.value);
});

const selectedApplication = ref({});
const showAcceptedApplicationForm = ref(false);
const openAcceptedApplicationForm = (application) => {
    showAcceptedApplicationForm.value = true;
    selectedApplication.value = application;
};

const showRejectedApplicationForm = ref(false);
const openRejectedApplicationForm = (application) => {
    showRejectedApplicationForm.value = true;
    selectedApplication.value = application;
};

const showAbsenceModal = ref(false);
const openAbsenceModal = (application) => {
    showAbsenceModal.value = true;
    selectedApplication.value = application;
};

const inviteForm = useForm({ application_id: '' });
const inviteApplicantToShift = (application) => {
    inviteForm.application_id = application.id;
    inviteForm.post(route('location.invited-applications.store'));
};

const openModalWithin96Hours = ref(false);

const userType = { freelancer: t('Freelancer'), temp_worker: t('Temp Worker') };

const tableSettings = [
    { label: t('Name'), sortBy: 'flexer.first_name', width: '25%', cell: 'NameAndLike' },
    { label: t('Type'), sortBy: 'flexer.type', width: '15%' },
    { label: t('Reviews'), sortBy: 'flexer.received_reviews_avg_rating', cell: 'Rating', width: '15%' },
    { label: t('Price per hour'), width: '15%' },
    { label: t('Distance'), width: '15%' },
    { label: '', width: '2%', cell: 'Cta' },
    { label: '', width: '2%', cell: 'Remove' },
    { label: '', width: '2%', cell: 'Arrow' },
];

const applications = computed(() => {
    const applications = [
        ...props.shift.child_shifts
            .filter((shift) => shift.selected_application)
            .map((shift) => shift.selected_application),
        props.shift.selected_application ? props.shift.selected_application : null,
        ...props.shift.applications,
    ];

    return applications.filter((application) => application);
});

function shouldBeInMainTable(application) {
    return (
        application.status !== 'retracted' &&
        application.status !== 'lapsed' &&
        application.status !== 'acceptance_lapsed' &&
        application.status !== 'rejected' &&
        application.status !== 'filled_by_others' &&
        application.status !== 'absent' &&
        application.status !== 'illness' &&
        application.status !== 'no_show'
    );
}

const tableData = computed(() =>
    applications.value
        .filter((a) => shouldBeInMainTable(a))
        .map((application) => {
            return {
                id: application.id,
                colorCode: application.status === 'accepted' ? '#1BB21B' : '#F89B29',

                rowData: [
                    [
                        application.flexer.display_name,
                        'bold',
                        application.flexer.id,
                        props.liked_flexer_ids.includes(application.flexer.id),
                    ],
                    userType[application.flexer.type],
                    [application.flexer.received_reviews_avg_rating, application.flexer.received_reviews_count],
                    '€' + (application.hourly_cost / 100).toFixed(2),

                    application.distance / 1000 + ' km',
                    [application, props.shift],
                    application.status === 'applied' ||
                        application.status === 'confirmation_required' ||
                        application.status === 'accepted',
                    application.flexer.deleted_at ? '' : route('location.flexers.show', { id: application.flexer.id }),
                ],
            };
        })
);

const retractedTableData = computed(() =>
    applications.value
        .filter((a) => !shouldBeInMainTable(a))
        .map((application) => {
            return {
                id: application.id,
                colorCode: application.status === 'accepted' ? '#1BB21B' : '#F89B29',
                inactive: true,
                rowData: [
                    [
                        application.flexer.display_name,
                        'bold',
                        application.flexer.id,
                        props.liked_flexer_ids.includes(application.flexer.id),
                    ],
                    userType[application.flexer.type],
                    [application.flexer.received_reviews_avg_rating, application.flexer.received_reviews_count],
                    '€' + (application.hourly_cost / 100).toFixed(2),

                    Math.round(application.distance / 1000) + ' km',
                    [application, props.shift],
                    application.status === 'applied' || application.status === 'confirmation_required',
                    route('location.flexers.show', { id: application.flexer.id }),
                ],
            };
        })
);

const whichButton = ({ row, column, id, cell }) => {
    if (cell === 'Cta') {
        let application = applications.value.find((app) => app.id === id);
        if (application.status === 'applied') {
            openAcceptedApplicationForm(application);
        } else if (application.status === 'confirmation_required') {
            inviteApplicantToShift(application);
        }
    } else if (cell === 'Remove') {
        let application = applications.value.find((app) => app.id === id);
        if (['applied', 'invited'].includes(application.status)) {
            openRejectedApplicationForm(application);

        } else {
            openAbsenceModal(application);
        }


    }
};
const showNoResult = computed(() => retractedTableData.value.length + tableData.value.length === 0);
</script>

<template>
    <div ref="summary" class="">
        <ShiftSummary :shift="shift">
            <div class="w-5 cursor-pointer group" @click="show = !show">
                <Icon
                    :icon="Right"
                    class="relative right-0 float-right w-3 h-12 transition-all duration-300 -translate-x-1 group-hover:-rotate-90"
                    :class="{ '-rotate-90 ': show }"
                    v-if="!dropdownOpen"
                />
            </div>
        </ShiftSummary>

        <div v-if="show || dropdownOpen" class="grid gap-2">
            <Table
                class="mb-8"
                :tableSettings="tableSettings"
                :data="tableData"
                @cellClicked="whichButton"
                :hideNoResults="!showNoResult"
            >
                <template #extraTableBody>
                    <tBody :headers="tableSettings" :data="retractedTableData" :hideNoResults="true" />
                </template>
            </Table>
        </div>
    </div>
    <ModalWithin96Hours v-model:showing="openModalWithin96Hours" />
    <AcceptedApplicationForm v-model:showing="showAcceptedApplicationForm" :application="selectedApplication" />
    <RejectedApplicationForm v-model:showing="showRejectedApplicationForm" :application="selectedApplication" />
    <AbsenceForm v-model:showing="showAbsenceModal" :application="selectedApplication" :shift="shift" />
</template>
